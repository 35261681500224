// Import our custom CSS
import '../scss/styles.scss'

import {Button, Carousel} from 'bootstrap'

// Run when document loaded
document.addEventListener('DOMContentLoaded', (event) => {
  const b = Button
  const c = Carousel

  // JavaScript code to decode the encoded email address
  const user = "info";
  const domain = "brnopride.cz";
  const element = document.getElementById("emailLink");

  element.setAttribute("href", "mailto:" + user + "@" + domain);
  element.textContent = user + "@" + domain;
});
